import * as Yup from "yup";
import { ReportTypes } from "../../../enums/reportType.enum";

export const ReportValidationSchema = Yup.object().shape({
  reportType: Yup.string().required("Report Type is required!"),
  month: Yup.string().test(
    "conditional-month-validation",
    "Month is required!",
    function (value) {
      const { reportType } = this.parent;

      if (reportType === ReportTypes.TRADING_REPORT) {
        return value != null && value.trim() !== "";
      }

      return true;
    }
  ),
});
