export const BeneficiaryFormConstants = {
  FROM_TITLE: "Add Beneficiary Details",
  FIELD_TITLES: {
    NAME: "Beneficiary Name",
    RELATION: "Relation",
    DOCUMENTATION:
      "Beneficiary Documentation  (ID, Passport, Alien ID, Certificate of Incorporation)",
    PHONE: "Beneficiary Contact Information (Phone Number or Email Address)",
  },
  VALIDATION_MESSAGES: {
    NAME_REQUIRED: "Beneficiary Name is required!",
    RELATION_REQUIRED: "Beneficiary Relation is required!",
    PHONE: {
      REQUIRED: "Beneficiary Contact Information is required!",
    },
  },
};
