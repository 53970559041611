import React, { useRef, useEffect, FC, Fragment, useState } from "react";
import DynamicText from "../../../shared/components/DynamicText";
import PageHeader from "../../../shared/components/PageHeader";
import "./report.scss";
import { Form, Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import DatePickerComponent from "../../../shared/components/DatePickerComponent";
import { ReportTypes } from "../../../enums/reportType.enum";
import { ReportModel } from "../../../models/ReportModel/report.model";
import moment from "moment";
import ReportService from "../../../services/ReportService/report.service";
import { ReportValidationSchema } from "./report.validation";

interface ReportProps {}

const Report: FC<ReportProps> = (props) => {
  const { loading, createReport, getMonths, availableMonths } = ReportService();

  const reportTypes = [
    { label: "Trading Report", value: ReportTypes.TRADING_REPORT },
  ];

  const handleReportTypeChange = (
    formik: FormikProps<ReportModel>,
    value: any
  ) => {
    formik.resetForm();

    formik.setFieldValue("reportType", value);

    if (value === ReportTypes.TRADING_REPORT) {
      getMonths();
    }
  };

  const handleMonthChange = (formik: FormikProps<ReportModel>, val: any) => {
    formik.setFieldValue("month", val);
  };

  const handleSubmit = async (formik: FormikProps<ReportModel>) => {
    if (Object.keys(formik.errors).length === 0) {
      await formik.submitForm();
      formik.resetForm();
    }
  };

  return (
    <div className="report__container">
      <div className="report__header">
        <PageHeader title="Reports" />
      </div>
      <div className="report__form">
        <Formik
          initialValues={new ReportModel()}
          onSubmit={createReport}
          validationSchema={ReportValidationSchema}
        >
          {(formik) => (
            <Form>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <DropdownField
                    title="Report Type"
                    options={reportTypes}
                    placeHolder="Select"
                    value={formik.values.reportType}
                    name={"reportType"}
                    onChange={(value) => handleReportTypeChange(formik, value)}
                  />
                </Col>
                <Col span={8}>
                  {formik.values.reportType === ReportTypes.TRADING_REPORT && (
                    <DropdownField
                      name="month"
                      loading={loading}
                      options={availableMonths?.map((month) => {
                        return {
                          label: month,
                          value: month,
                        };
                      })}
                      title="Select Month"
                      onChange={(val) => handleMonthChange(formik, val)}
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={16} justify="end" className="pt-2">
                <Col>
                  <ButtonComponent
                    type="primary"
                    htmlType="submit"
                    onClick={() => handleSubmit(formik)}
                    loading={loading}
                  >
                    Run Report
                  </ButtonComponent>
                </Col>
                <Col>
                  <ButtonComponent
                    className="secondary"
                    type="default"
                    onClick={formik.resetForm}
                  >
                    Cancel
                  </ButtonComponent>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Report;
