import { Button } from "antd";
import React, { Fragment, useEffect, useState, Component } from "react";
import { Customer } from "../../../models/Customer/customer.model";
import "./profileOtherDetailsCard.scss"

interface CardContent {
    title: string
    icon: string
    iconBgColor: string
    contents: {
        label: string,
        value?: string | number
    }[]
}

interface ProfileOtherDetailsCardProps {
    data: CardContent | undefined
    setVisible: (visibility: boolean, editMode?: boolean) => void
}

const ProfileOtherDetailsCard = (props: ProfileOtherDetailsCardProps) => {

    const {
        data,
        setVisible,
    } = props

    const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false)

    useEffect(() => {
        if (data)
            setIsDataEmpty(data.contents.every(content => !content.value))
        else
            setIsDataEmpty(true)
    }, [data])

    return <div className="profile-other-details">
        {!isDataEmpty
            ? <Fragment>
                <div className="profile-other-details__header">
                    <div className="profile-other-details--title">
                        <div className={`title-icon ${data?.iconBgColor}`}>
                            <img src={data?.icon} alt="profile-other" />
                        </div>
                        {data?.title}
                    </div>
                    <div className="profile-other-details--editBtn">
                        { data?.title == "Bank Details" && 
                            <Button onClick={() => setVisible(true, true)}>
                            Edit
                          </Button>
                        }
                        </div>
                    {/* <Button
                        onClick={() => setVisible(true, true)}
                    >Edit</Button> */}
                </div>
                <div className="profile-other-details__body">
                    {
                        data?.contents.map(content => <div key={content?.label} className="profile-other-details--content">
                            <div className="profile-other-details--content-title">{content.label}</div>
                            <div className="profile-other-details--content-value">{content.value}</div>
                        </div>)
                    }
                </div>
            </Fragment>
            : <div className="profile-other-details__empty-state">
                <div className="profile-other-details--title">
                    <div className={`title-icon ${data?.iconBgColor}`}>
                        <img src={data?.icon} alt="profile-other" />
                    </div>
                    {data?.title}
                </div>
                <p className="profile-other-details--empty-note">
                    Add {data?.title.toLowerCase()} for your account
                </p>
                <Button className="profile-other-details--empty-btn" onClick={() => setVisible(true)}>
                    Add {data?.title}
                </Button>
            </div>}
    </div>

}

export default ProfileOtherDetailsCard