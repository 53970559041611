import { Button, Col, Divider, Form, Radio, RadioChangeEvent, Row, Skeleton } from "antd";
import { Formik, FormikProps } from "formik";
import React, { Dispatch, FC, Fragment, useEffect, useRef, useState } from "react";
import InputField from "../InputField";
import StyledModal from "../StyledModal";
import WalletIcon from "./../../../assets/icons/multi color icons - svg/dynamic wallet - light blue.svg";
import WalletStaticIcon from "./../../../assets/icons/multi color icons - svg/static wallet - blue.svg";
import Logo from "../../../assets/icons/logo - svg/mpesa.png";
import MPesaIcon from "./../../../assets/icons/single color icons - svg/Security check.svg";
import "./walletBalance.scss";
import { inputNumberConvention, numberConvention } from "../../utils/numberConvention";
import { Wallet } from "../../../models/Wallet/wallet.model";
import ShareService from "../../../services/SharesService/share.service";
import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";
import { recursiveRequest } from "../../utils/recursiveRequest";
import PaymentProcessingModal from "../PaymentProcessingModal";
import DynamicText from "../DynamicText";
import BankIcon from "./../../../assets/icons/multi color icons - svg/bank details.svg";
import { Withdraw } from "../../../models/Withdraw/withdraw.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import OTPForm from "../OTPForm";
import UIModal from "../UIModal";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import Bank from "../../../assets/icons/multi color icons - svg/bank details.svg";
import MpesaTransferInstructions from "../MpesaTransferInstructions";
import BankTransferInstructions from "../BankTransferInstructions";
import { OrderText } from "../../constants/orderText.constant";

interface WalletBalanceProps extends TermsAndConditionsReducerProps, AuthReducerProps {
  wallet?: Wallet;
  loading: boolean;
  fetchWallet: () => void
}

const paymentValidationSchema = yup.object().shape({
  mobileNumber: yup
    .string()
    .matches(REGEX.NUMERIC, "Mobile number should be of numeric")
    .min(9, "Mobile number must be at least 9 characters")
    .max(10, "Mobile number must be atmost 10 characters")
    .required("Mobile number is required"),
});

const withdrawalValidationSchema = yup.object().shape({
  paymentMode: yup
    .number()
    .required("Mode of payment is required"),
  mobileNumber: yup.string()
    .ensure().when('paymentMode', {
      is: (paymentModeId) => paymentModeId === 2,
      then: yup
        .string()
        .required("Mobile number is required!")
        .matches(REGEX.NUMERIC, "Mobile number should be of numeric")
        .min(9, "Mobile number must be at least 9 characters")
        .max(10, "Mobile number must be atmost 10 characters")
    }),
});

const WalletBalance: FC<WalletBalanceProps> = (props) => {
  const { wallet, fetchWallet, loading, showTermsAndConditions, accept, user } = props;

  const history = useHistory()

  const {
    createPayment,
    isSubmitting,
    createOTPRequest,
    createWithdrawRequest,
    verifyWithdrawOTP,
    isSecondarySubmitting,
  } = ShareService();

  const [withdrawModalVisible, setWithdrawModalVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>();
  const [withdrawAmount, setWithdrawAmount] = useState<string>();
  const [otpModalVisibility, setOtpModalVisibility] = useState<boolean>(false)
  const [withdrawConfirmationVisibility, setWithdrawConfirmationVisibility] = useState<boolean>(false)

  const [paymentMode, setPaymentMode] = useState<number>(3);

  const formikRef = useRef<FormikProps<any>>(null)
  const withdrawFormikRef = useRef<FormikProps<any>>(null)

  useEffect(() => {
    if (!modalVisible) formikRef.current?.resetForm()
  }, [modalVisible])

  const handleChange = (val: string, setState: Dispatch<string>) => {
    const value = val.replace(/\D/g, "");
    const number = parseInt(value === "" ? "1" : value);
    if (isNaN(number)) {
      return;
    }
    const formattedNumberString = inputNumberConvention(number) + " KES";
    setState(formattedNumberString);
  };

  const handleBackspace = (e: any, setState: Dispatch<(prev: any) => string | undefined>) => {
    if (e.keyCode === 8) {
      setState((prev) => {
        const value = prev?.replace(/\D/g, "") || "";
        const number = parseInt(value === "" ? "1" : value);
        if (isNaN(number)) {
          return;
        }
        return inputNumberConvention(Math.floor(number / 10)) + " KES";
      });
    }
  };

  const paymentModeChanged = (e: RadioChangeEvent) => {
    setPaymentMode(e.target.value);
    formikRef.current?.setFieldValue("mobileNumber", undefined)
    formikRef.current?.setFieldTouched("mobileNumber", false)
  };

  const handleSubmit = async (values: any) => {
    try {
      const response = await createPayment({
        ...values,
        amount: amount?.replace(/\D/g, ""),
      });
      let processedResponse;
      if (response?.dynamicUrl) {
        setIsProcessing(true);
        processedResponse = await recursiveRequest(response.dynamicUrl);
      }
      if (processedResponse === "Success") {
        setModalVisible(false);
      }
      setIsProcessing(false);
      fetchWallet()
    } catch (error) { }
  };

  const handleWithdrawSubmit = async () => {
    const response = await createWithdrawRequest({ ...withdrawFormikRef.current?.values, amountRequested: parseInt(withdrawAmount?.replaceAll(" KES", '')?.replaceAll(",", "") || '0') });
    if (response) {
      withdrawFormikRef.current?.resetForm()
      setWithdrawConfirmationVisibility(false)
      fetchWallet()
      modalCloseHandler()
    }
  }

  const formikProps = {
    initialValues: {
      mobileNumber: "",
      isAgreed: false,
      dialCode: 254,
    },
    innerRef: formikRef,
    onSubmit: handleSubmit,
    validationSchema: paymentValidationSchema,
    enableReinitialize: true
  };

  const withdrawalFormikProps = {
    initialValues: new Withdraw(),
    onSubmit: handleWithdrawSubmit,
    validationSchema: withdrawalValidationSchema,
    innerRef: withdrawFormikRef,
    enableReinitialize: true
  };

  const modalCloseHandler = () => {
    setAmount(undefined)
    setModalVisible(false)
    setWithdrawModalVisible(false)
    setWithdrawAmount(undefined)
    formikRef.current?.resetForm()
  }

  const generateOTPHandler = async () => {
    try {
      const response = await createOTPRequest({ ...withdrawFormikRef.current?.values, amountRequested: parseInt(withdrawAmount?.replaceAll(" KES", '')?.replaceAll(",", "") || '0') });
      if (!response) return
      setOtpModalVisibility(true)
    } catch (error) {

    }
  }

  const otpVerificationHandler = async (otp: string) => {
    try {
      const response = await verifyWithdrawOTP(otp);
      if (!response) return
      setOtpModalVisibility(false)
      setWithdrawConfirmationVisibility(true)
    } catch (error) {

    }
  }

  const WalletActionTemplate = (
    <Fragment>
      <div className="main-content">
        <p className="title">
          {OrderText.TITLE}
        </p>
        <div className="wallet-form">
          <input
            type="text"
            className="wallet-form--field"
            placeholder="0 KES"
            value={amount}
            name="amount"
            onKeyDown={(e) => handleBackspace(e, setAmount)}
            onChange={(e) => handleChange(e.target?.value, setAmount)}
          />
        </div>
      </div>
      <div className="content-template">

        <Row align="middle">
          <Col span={2}>
            <Radio
              value={1}
              checked={paymentMode === 1}
              onChange={paymentModeChanged}
            />
          </Col>
          <Col span={22}>
            <div className="wallet-form__payment-method justify-between">
              <p>Pay using Bank Account</p>
              <div className="bank-icon__container">
                <img
                  className="m-pesa-icon"
                  src={Bank}
                  alt="m-pesa-icon"
                />
              </div>
            </div>
          </Col>
        </Row>
        {paymentMode === 1 && (
          <BankTransferInstructions
            customerId={String(user?.uid).split("-").pop() || ""}
          />
        )}
        <Divider />
        <Row align="middle">
          <Col span={2}>
            <Radio
              value={2}
              checked={paymentMode === 2}
              onChange={paymentModeChanged}
            />
          </Col>
          <Col span={22}>
            <div className="wallet-form__payment-method justify-between">
              <p>Pay using Mpesa App</p>
              <img
                className="m-pesa-icon"
                src={Logo}
                alt="m-pesa-icon"
              />
            </div>
          </Col>
        </Row>
        {paymentMode === 2 && (
          <MpesaTransferInstructions
            amount={Number(amount?.replaceAll(",", "")?.replace(" KES", "")) || 0}
            customerId={String(user?.uid).split("-").pop() || ""}
          />
        )}
        <Divider />
        <Row align="middle">
          <Col span={2}>
            <Radio
              value={3}
              checked={paymentMode === 3}
              onChange={paymentModeChanged}
            />
          </Col>
          <Col span={22}>
            <div className="wallet-form__payment-method">
              <p>Pay using</p>
              <img
                className="m-pesa-icon"
                src={Logo}
                alt="m-pesa-icon"
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer">
        <Formik {...formikProps}>
          {({ isValid, dirty, setFieldValue, values }) => {
            return (
              <Form className="wallet-form">
                <InputField
                  className="mpesa-field lg"
                  type="text"
                  name="mobileNumber"
                  placeholder="Enter MPESA mobile number"
                  onChange={(e) => {
                    setFieldValue("mobileNumber", e.target?.value)
                    setPaymentMode(3)
                  }}
                />
                <Checkbox
                  className="terms-acceptance--checkbox"
                  name="isAgreed"
                  checked={accept}
                  onChange={showTermsAndConditions}
                >
                  <span className="terms-acceptance-label">
                    I have read all the{" "}
                    <b onClick={showTermsAndConditions}>Terms and Conditions</b>{" "}
                    and agree to the same
                  </span>
                </Checkbox>
                <div className="styled-modal-btn">
                  {paymentMode === 3
                    ? <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      disabled={
                        !accept ||
                        !amount?.replaceAll("/Dg/", "") ||
                        !values.mobileNumber
                      }
                      onClick={() => handleSubmit(values)}
                      loading={isSubmitting}
                    >
                      {`Add Money to wallet`}
                    </Button>
                    : <Button
                      type="primary"
                      htmlType="button"
                      size="middle"
                      onClick={() => setModalVisible(false)}
                      loading={isSubmitting}
                    >
                      {`Okay`}
                    </Button>}
                  <p className="payment_form__footer">
                    <img src={MPesaIcon} alt="mpesa" />
                    MPESA: 100% Payment Protection
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );

  const WalletWithdrawActionTemplate = (
    <Fragment>
      <div className="main-content">
        <p className="title">
          {`Enter the amount to be withdrawn from your wallet`}
        </p>
        <div className="wallet-form">
          <input
            type="text"
            className="wallet-form--field"
            placeholder="0 KES"
            value={withdrawAmount}
            name="withdrawAmount"
            onKeyDown={(e) => handleBackspace(e, setWithdrawAmount)}
            onChange={(e) => handleChange(e.target?.value, setWithdrawAmount)}
          />
        </div>
      </div>

      <div className="footer">
        <Formik {...withdrawalFormikProps}>
          {({ isValid, dirty, setFieldValue, submitForm }) => {
            return (
              <Form className="wallet-form">
                <Radio.Group onChange={(e) => setFieldValue("paymentMode", e.target.value)}>
                  <Radio value={1} disabled={!user?.kycBankDetails?.bankName}>
                    <Row className="withdraw-option-bank">
                      <Col >
                        <p className="option text-left">Withdraw to Bank Account</p>
                        {user?.kycBankDetails?.bankName
                          ? <p className="bank">Bank {user?.kycBankDetails?.bankName} - {user?.kycBankDetails?.accountNumber}</p>
                          : <p>Bank details not available</p>}
                      </Col>
                      <Col>
                        <div className="icon-cover">
                          <img src={BankIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                    {!user?.kycBankDetails?.bankName && <Col span={24} className="text-left">
                      <label className="bank-details--add" onClick={() => {
                        setWithdrawModalVisible(false)
                        history.push(AppRoutes.PROFILE)
                      }}>
                        Add now
                      </label>
                    </Col>}
                  </Radio>
                  <Radio value={2}>
                    <p className="mpesa-option">
                      Withdraw to MPESA Account
                      <img className="m-pesa-icon" src={Logo} alt="m-pesa-icon" />
                    </p>
                    <div className="input-cover">
                      <InputField
                        type="text"
                        name="mobileNumber"
                        className="mpesa-field lg"
                        placeholder="Enter MPESA mobile number"
                      />
                    </div>
                  </Radio>
                </Radio.Group>

                <div className="styled-modal-btn">
                  <div className="withdraw-footer">
                    <Checkbox
                      className="terms-acceptance--checkbox"
                      name="isAgreed"
                      checked={accept}
                      onChange={showTermsAndConditions}
                    >
                      <span className="terms-acceptance-label">
                        I have read all the{" "}
                        <b onClick={showTermsAndConditions}>
                          Terms and Conditions
                        </b>{" "}
                        and agree to the same
                      </span>
                    </Checkbox>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    disabled={
                      !isValid || !dirty || !accept || !withdrawAmount
                    }
                    loading={isSecondarySubmitting}
                    onClick={generateOTPHandler}
                  >
                    {`Withdraw Amount`}
                  </Button>
                  <p className="payment_form__footer">
                    <img src={MPesaIcon} alt="mpesa" />
                    MPESA: 100% Payment Protection
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );

  return (
    <div className="wallet__container">
      <div className="wallet__dynamic">
        <div className="wallet__dynamic--balance">
          <div className="wallet__dynamic--wallet-icon">
            <img src={WalletIcon} alt="wallet" />
          </div>
          <div>
            <p className="wallet__dynamic--balance--title">
              Dynamic Wallet Balance
            </p>
            {!loading ? (
              <DynamicText
                text={` ${numberConvention(wallet?.dynamicBalance)} KES`}
                className="wallet__dynamic--balance--value"
                width="170px"
                fontSize={28}
              />
            ) : (
              <Skeleton.Input active={true} size="large" />
            )}
          </div>
        </div>
        <div className="wallet__dynamic--actions">
          <Button
            className="primary"
            type="primary"
            onClick={() => setModalVisible(true)}
          >
            Add Money
          </Button>
          <Button
            className="default"
            disabled={(wallet?.dynamicBalance || 0) <= 0}
  
            onClick={() => setWithdrawModalVisible(true)}
          >
            Withdraw Money
          </Button>
        </div>
      </div>
      <div className="wallet__static">
        <div className="wallet__static--balance">
          <div className="wallet__static--wallet-icon">
            <img src={WalletStaticIcon} alt="wallet" />
          </div>
          <div>
            <p className="wallet__static--balance--title">
              Static Wallet Balance
              <i className="icon-ic-info-24px" />
            </p>
            {!loading ? (
              <DynamicText
                text={` ${numberConvention(wallet?.staticBalance)} KES`}
                className="wallet__static--balance--value"
                width="170px"
                fontSize={28}
              />
            ) : (
              <Skeleton.Input active={true} size="large" />
            )}
          </div>
        </div>
      </div>
      <StyledModal
        visible={modalVisible}
        title="Add Money to Dynamic wallet"
        className="dark-bg"
        dynamicWalletBalance={wallet?.dynamicBalance || 0}
        staticWalletBalance={wallet?.staticBalance || 0}
        content={WalletActionTemplate}
        okText="Add Money to Wallet"
        onCancel={modalCloseHandler}
      />
      <StyledModal
        visible={withdrawModalVisible}
        title="Withdraw from Dynamic wallet"
        className="dark-bg"
        dynamicWalletBalance={wallet?.dynamicBalance || 0}
        staticWalletBalance={wallet?.staticBalance || 0}
        content={WalletWithdrawActionTemplate}
        okText="Withdraw Amount"
        onCancel={modalCloseHandler}
      />
      <PaymentProcessingModal visible={isProcessing} />
      <OTPForm
        loading={isSecondarySubmitting}
        otpModalVisibility={otpModalVisibility}
        otpSubmissionHandler={(values) => values.otp && otpVerificationHandler(values.otp)}
      ></OTPForm>
      <UIModal
        className="withdraw-req__confirmation"
        visible={withdrawConfirmationVisibility}
        loading={isSubmitting}
        closable={false}
        onClose={() => {
          modalCloseHandler()
          setWithdrawConfirmationVisibility(false)
        }}
        onOk={() => handleWithdrawSubmit()}
      >
        Are you sure want to create a withdraw request for {withdrawAmount}?
      </UIModal>
    </div>
  );
};

export default termsAndConditionsContainer(AuthContainer(WalletBalance));
