import { useState } from "react";
import { ReportModel } from "../../models/ReportModel/report.model";
import { serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const ReportService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [availableMonths, setAvailableMonths] = useState<string[]>();

  const createReport = async (reportData: ReportModel) => {
    try {
      setLoading(true);
      const params = serialize(ReportModel, reportData);
      const response = await axiosInstance.get(ApiRoutes.TRADING_REPORT, {
        params,
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");

      const a = document.createElement("a");
      a.href = url;
      a.download = "trading_report.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
      return response.data;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getMonths = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.AVAILABLE_MONTHS);
      const data = response.data["data"];
      setAvailableMonths(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    createReport,
    loading,
    getMonths,
    availableMonths
  };
};

export default ReportService;
