import * as yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";
import { BeneficiaryFormConstants } from "./constants";

export const beneficiaryDetailsValidation = yup.object().shape({
  name: yup
    .string()
    .required(BeneficiaryFormConstants.VALIDATION_MESSAGES.NAME_REQUIRED),
  relation: yup
    .string()
    .required(BeneficiaryFormConstants.VALIDATION_MESSAGES.RELATION_REQUIRED),
  phone: yup
    .string().required(BeneficiaryFormConstants.VALIDATION_MESSAGES.PHONE.REQUIRED)
});
